:root // global wish to share with components

    {
    /* Colors */
    --login-btn: #1a589b;
    --active-color: #FD7F50;
    --border-color: #5352ED;
    --text-color: #5352ED;
    --mat-bg: #5352ED;

    --promo-btn-color: #1A2640;

    --bright-bg: #007AFF;
    --promo-btn-bg: #007AFF;
    --tournament-menu-bg: #1a589b;
    --tournament-card-disable: #1A2640;
    --disable-text-color: #32446A;

    --card-theme-bg: #10192D;
    --tab-bg: #2A3150;
    --tag-bg: #2A3150;
    --icon-btn: #32446A;
    --icon-btn-hover: #374270;
    --border-bg-popup: #32446A;
    --options-bg: #10192D;
    --scroller-bg: #32446A;

    --active-tab-bg: #32446A;
    --search-tab-bg: #10192d;
    --dark-bg: #1A2640;
    --signup-btn: #FF7F50;
    --border-dark: #ff7f50;
    --color-dark: #ff7f50;

    --btn-primary-hover: #006ADD;
    --btn-primary-active: #007affc7;
    // --btn-primary-hover:#5352ed3b;
    // --btn-primary-active:#5352ed85;
    --btn-warn-hover: #5352ED;
    --btn-accent-hover: #D96D45;
    --btn-accent-active: #ff7f50e6;
    --btn-orange-hover: #D96D45;
    --btn-orange-active: #ff7f50e6;
    --btn-disabled: #10192D;

    --card-new-border: #E6BC64;
    --btn-hover-bg: #FF9533;

    --table-bg: #203c71;
    --light-grey: #dadada;

    --anchor-color: #FD7F50;
    --circle-color: #FFE5AA;

    --text-white: #FFFFFF;

    --a-tag-text-color-white: #FFFFFF;

    --a-tag-text-color: #FD7F50;
    --a-tag-hover-color: #FD7F50;

    // --btn-text-color: #1B2827;
    --table-odd-bg: #2a3150;
    --box-bg: #2a3150;
    --btn-hover: #5352ed3b;
    --btn-active-bg: #5352ed85;
    --btn-filled-hover: #006ADD;
    --btn-empty-hover: #696050;
    --table-new-bg: #1B4B48;
    --btn-hover: #FFD36C33;
    --btn-arrow-bg: #2F615D;
    --btn-arrow-hover: #439D97;

    --table-new-bg: #162036;
    --new-border: #162036;
    --box-bg-menu: #162036;
    --new-msg-bg: #2A3150;

    --bottom-bar: #2A3050;
    --wallet-bg: #4065B5;

    --text-light-grey: #DADADA;
    --text-color-white: #ffffff;
    --btn-text-color: #ffffff;
    --header-bg: #162036;
    --body-bg: #162036;
    --sidenav-bg: #10192D;
    --top-menu-bg: #1a589b;
    --table-border: #214068;
    --table-new-bg: #1B4B48;
    --new-border: #1B4B48;
    --box-bg-menu: #1B4B48;
    --spiner-bg: #32446A;
    --spiner-border: #10192D;
    --completed-badge: #1B4B48;

    --badge-bg-0: #cf142b;
    --badge-color-0: #ffffff;
    --badge-bg-1: #e3ff39;
    --bg-bet: #1d406a;
    --color-text-bet: #1d406a;

    --jackpot-bg: #FD7F50;

    --title-head-bg: rgba(30, 144, 255, 0.25);
    --content-bg: rgba(30, 144, 255, 0.25);
    --divider-border: #FD7F50;
    --popup-bg: #1b2538;
    --sidenav-hover: #162036;

    --soft-text: #ADBDDE;

    --main-bg: #162036;
    --main-color: #162036;
    --dark-main: #162036;
    --list-item-bg: #32446a;
    --batch-color: #439d97;

    --search-icon-bg: #32446a;
    --table-head-bg: #2F615D;
    --head-bg: #2F615D;

    --table-head-bg: rgba(30, 144, 255, 0.25);

    // --tab-bg: #1D406A;

    --dark-table-bg: #162036;

    --soft-dark: #2c3a59;

    //border-radius
    --radius-12: 12px;

    --select-option: #1a589b;
    // desktop screen size
    --desktop-width: 1248px;
    // desktop screen size
    --mobile-width: 940px;

    //gloabl font-family
    --global-font: 'Lato';
    --category-font: 'digitalt';

    --global-bg: #10192D;
    --progress-bar-color: #ff7f50;
    --verified-bg: #ff7f50;

    //login color
    --login-divider-color: #2A3150;
    --login-text-color: #ADBDDE;
    --login-label-color: #ADBDDE;
    --login-input-color: #DADADA;
    --login-title-color: #FFFFFF;
    --login-input-bg: #03060E;
    --login-input-border: #2A3150;
    --login-input-border-focused: #FD7F50;
    --login-input-error-color: #FF5252;
    --login-btn-color: #10192D;
    --accent-text-color: #10192D;

    //lost password
    --lostpassword-text-color: #DADADA;
    --lostpassword-bold-color: #FFFFFF;
    --image-border-gradient: linear-gradient(to left, #E6BC64, #E1D696, #FAF8CA, #F6DA93, #F5C553);
    --image-border: #439D97;
    --image-bg: #3C7B77;
    --badge-bg-2: #39D0FF;

    --progress-bar-bg: #03060E;

    --main-back-bg: #1A2640;
    --border-new-tire: #FFDF9E;
    --border-bronze-tire: #FFDF9E;
    --gradient: linear-gradient(to top, #FFFFFF, #5352ED);
    --gradient-level-border: linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient(to right, #FFD36C, #3C7B77) border-box;
    --cta-gradient: linear-gradient(180deg, #32446B 25.5%, #32446A 100%) padding-box, var(--gradient) border-box;
    --active-day-gradient: linear-gradient(180deg, var(--mat-bg) 25.5%, var(--mat-bg) 100%) padding-box, var(--gradient) border-box;
    --special-day-gradient: linear-gradient(180deg, var(--global-bg) 25.5%, var(--global-bg) 100%) padding-box, var(--gradient) border-box;
    --card-gradient-border: linear-gradient(#1A2640 40%, #2F3680 100%) padding-box, linear-gradient(180deg, #5352ED 0%, #FD7F50 100%) border-box;
    --days-gradient-border: linear-gradient(180deg, #439D97 25.5%, #439D97 100%) padding-box, linear-gradient(#E0101D, #FF9F47) border-box;
    --cta-button-gradient-border: linear-gradient(#32446B 25.5%, #32446A 100%) padding-box, linear-gradient(180deg, #5352ED 0%, #FD7F50 100%) border-box;
    // linear-gradient(rgba(83, 82, 237, 0.00), rgba(83, 82, 237, 0.40)),  #1A2640 padding-box, 
    // linear-gradient(180deg, #5352ED 0%, #FD7F50 100%) border-box;
    --gradient-border:
        linear-gradient(#1A2640, #1A2640) padding-box,
        linear-gradient(180deg, #5352ED 0%, #FD7F50 100%) border-box;
    --xmass-box-bg: #0C1527;
    --gradient-border-mobile: linear-gradient(#1A2640, #1A2640) padding-box, linear-gradient(260deg, #5352ED 0%, #FD7F50 100%) border-box;
    --card-next_btn-gradient-border:
        linear-gradient(0deg, #1A2640, #1A2640) padding-box,
        linear-gradient(360deg, #5352ED 0%, #FD7F50 100%) border-box;
    --xmas-card-background-gradient: linear-gradient(#1A2640 40%, #2F3680 100%);
    --gradient-background: linear-gradient(180deg, #1A4B48 0%, #3C7B77 100%);
    --notify-gradient-border: linear-gradient(180deg, #1B2827 25.5%, #1B2827 100%) padding-box, linear-gradient(to right, #439D97, #3C7B77) border-box;
    --level-gradient-border: linear-gradient(180deg, #1B2827 25.5%, #1B2827 100%) padding-box, linear-gradient(to right, #FFD36C, #439D97) border-box;
    --reactivation-border: linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient(90deg, #FFD36C, #FFE5AA, #FFD36C) border-box;
    --xmas-border: linear-gradient(180deg, #3C7B77 25.5%, #1B2827 100%) padding-box, linear-gradient(to bottom, #F9C839, #E0101D) border-box;
    --level-change-border: linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient(to right, #FFD36C, #439D97) border-box;
    --vip-border: linear-gradient(270deg, #E6BC64 -5.72%, #E1D696 22.44%, #FAF8CA 49%, #F6DA93 77.69%, #F5C553 100%) padding-box, linear-gradient(to bottom, #E6BC64, #E6BC64) border-box;


    --leve-img-border: linear-gradient(180deg, #3C7B77 100%, #3C7B77 100%) padding-box, linear-gradient(to bottom, #FFD36C, #439D97) border-box;
    --unique-img-border: linear-gradient(180deg, #3C7B77 100%, #3C7B77 100%) padding-box, linear-gradient(to bottom, #439D97, #439D97) border-box;
    --default-img-border: linear-gradient(180deg, #1B4B48 100%, #1B4B48 100%) padding-box, linear-gradient(to bottom, #439D97, #439D97) border-box;
    --reactivation-img-border: linear-gradient(180deg, #1B2827 100%, #1B2827 100%) padding-box, linear-gradient(to bottom, #FFD36C, #FFD36C) border-box;
    --xmas-img-border: linear-gradient(180deg, #1B2827 100%, #1B2827 100%) padding-box, linear-gradient(to bottom, #E0101D, #F9C839) border-box;
    --excluded-game-text: #B8B8B8;
    --excluded-game-btn-text: #1B2827;
    --excluded-game-light-gray: #D5CFD8;
    //vip tire
    --bronze-front-gradient-level-border: linear-gradient(to right, rgba(160, 111, 68, 0.50), rgba(160, 111, 68, 0.50));
    --bronze-back-gradient-level-border: linear-gradient(180deg, #8B603A 0%, #A06F44 100%), linear-gradient(0.2deg, rgba(160, 111, 68, 0) 0.27%, rgba(255, 203, 157, 0.4) 99.83%);
    --silver-front-gradient-level-border: linear-gradient(to right, rgba(159, 159, 159, 0.50), rgba(159, 159, 159, 0.50));
    --silver-back-gradient-level-border: linear-gradient(180deg, #D2D2D2 0%, #696969 100%);
    --gold-front-gradient-level-border: linear-gradient(#e6bc6480, #e6bc6480);
    --gold-back-gradient-level-border: linear-gradient(180deg, #E6BC64 0%, #806938 100%) padding-box, linear-gradient(0.2deg, rgba(230, 188, 100, 0) 0.27%, rgba(230, 188, 100, 0.15) 99.83%) border-box;
    --promotions-box-border: linear-gradient(#32446A 0%, #5352ED 100%) padding-box, linear-gradient(0deg, #32446A 0%, #5352ED 100%) border-box;
    --promotions-box-background: linear-gradient(90deg, #080e0c 0%, rgba(8, 14, 12, 0.84) 5%, rgba(8, 14, 12, 0) 94%);
    --promotion-background: linear-gradient(180deg, #1A2640 33%, #32446A 100%);
    --deposit-bar-gradient: linear-gradient(180deg, #32446A 0%, #1A2640 67%);
    --deposit-bar-gradient-border: #32446A;
    --deposit-bar-box-shadow: rgba(0, 0, 0, 0.32);

}
